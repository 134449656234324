$( document ).ready(function() {
    _accessibility();
    $(document).on('pagetypelisting_callback', function() {
        _accessibility();
    });
});
const _accessibility = () => {
    $('a[target="_blank"]').each(function (e) {
        let _link = $(this);
        if ( _link.attr('aria-label') && _link.attr('aria-label').length > 0 ) {
            // console.log(_link.attr('aria-label'), _link.attr('aria-label').length);
            _link.attr('aria-label', _link.attr('aria-label') + ' (open in new window)');
        }
        else {
            _link.attr('aria-label', '(open in new window)');
        }
    });
}